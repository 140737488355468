var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "input-wrapper search-wrapper",
        class: { "exist-value": _vm.contents || _vm.isFocused },
      },
      [
        _c("label", { attrs: { for: _vm.id } }, [
          _c(
            "button",
            {
              staticClass: "input-left-icon",
              attrs: { "data-icon": "input-left-icon" },
              on: { click: _vm.onClick },
            },
            [_c("vue-material-icon", { attrs: { name: "search" } })],
            1
          ),
          _c("input", {
            staticClass: "search-input",
            attrs: {
              id: _vm.id,
              type: "text",
              placeholder: _vm.placeholder,
              autocomplete: "off",
            },
            domProps: { value: _vm.value },
            on: {
              input: _vm.onChange,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onKeyUpEnter.apply(null, arguments)
              },
              focusin: function ($event) {
                _vm.isFocused = true
              },
              focusout: function ($event) {
                _vm.isFocused = false
              },
            },
          }),
        ]),
      ]
    ),
    _vm.infoMessage !== ""
      ? _c(
          "p",
          { staticClass: "info-text" },
          [
            _c("vue-material-icon", { attrs: { name: "error" } }),
            _vm._v(" " + _vm._s(_vm.infoMessage) + " "),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="utility-menu-box">
    <div>
      <slot name="calendar" />
      <search-box
        v-if="hasSearchInput"
        name="search"
        id="listHeader"
        type="text"
        :placeholder="placeholder"
        @input="onChange"
        @button="onClick"
        @keyupenter="onKeyUpEnter"
        :value="value"
      />
    </div>
    <slot name="button" class="button-wrapper" />
  </div>
</template>
<script>
import SearchBox from '@/component/search/SearchBox.vue';

export default {
  name: 'ListUtilityMenu',
  props: {
    hasSearchInput: {
      type: Boolean,
      require: false,
      default: false,
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    autocomplete: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onChange(changeValue) {
      this.$emit('input', changeValue);
    },
    onKeyUpEnter() {
      this.$emit('keyupenter');
    },
    onClick() {
      this.$emit('button');
    },
  },
  components: { SearchBox },
};
</script>
<style scoped lang="scss">
@import 'List';
</style>

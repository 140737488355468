<template>
  <div>
    <div class="input-wrapper search-wrapper" :class="{ 'exist-value': contents || isFocused }">
      <label :for="id">
        <button @click="onClick" class="input-left-icon" data-icon="input-left-icon">
          <vue-material-icon name="search" />
        </button>
        <input
          :id="id"
          type="text"
          class="search-input"
          :placeholder="placeholder"
          :value="value"
          autocomplete="off"
          @input="onChange"
          @keyup.enter="onKeyUpEnter"
          @focusin="isFocused = true"
          @focusout="isFocused = false"
        />
      </label>
    </div>
    <p class="info-text" v-if="infoMessage !== ''">
      <vue-material-icon name="error"></vue-material-icon>
      {{ infoMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: {
    id: {
      type: String,
      require: false,
      default: '',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
    },
    infoMessage: {
      type: String,
      require: false,
      default: '',
    },
    value: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      isFocused: false,
      contents: '',
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    onKeyUpEnter() {
      this.$emit('keyupenter');
    },
    onChange($event) {
      this.$emit('input', $event.target.value);
    },
    onClick() {
      this.$emit('button');
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Search';
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "utility-menu-box" },
    [
      _c(
        "div",
        [
          _vm._t("calendar"),
          _vm.hasSearchInput
            ? _c("search-box", {
                attrs: {
                  name: "search",
                  id: "listHeader",
                  type: "text",
                  placeholder: _vm.placeholder,
                  value: _vm.value,
                },
                on: {
                  input: _vm.onChange,
                  button: _vm.onClick,
                  keyupenter: _vm.onKeyUpEnter,
                },
              })
            : _vm._e(),
        ],
        2
      ),
      _vm._t("button"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }